import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  // {
  //   path: '/staking',
  //   name: 'staking',
  //   component: () => import('@/views/StakingView.vue'),
  //   props: true,
  // },
  // {
  //   path: '/wallet',
  //   name: 'wallet',
  //   component: () => import('@/views/WalletView.vue')
  // },
  {
    path: '/board',
    name: 'board',
    component: () => import('@/views/BoardView.vue')
  },
  {
    path: '/boardDetail/:id',
    name: 'boardDetail',
    component: () => import('@/views/BoardDetailView.vue'),
  },
  {
    path: '/boardadmin',
    name: 'boardAdmin',
    component: () => import('@/views/BoardAdminView.vue')
  },
  {
    path: '/boardDetailAdmin/:id',
    name: 'boardDetailAdmin',
    component: () => import('@/views/BoardDetailAdminView.vue')
  },
  {
    path: '/addPost',
    name: 'addPost',
    component: () => import('@/views/AddPostView.vue')
  },
  {
    path: '/loginAdmin',
    name: 'loginAdmin',
    component: () => import('@/views/LoginAdminView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
